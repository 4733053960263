import { Pivot, PivotItem } from "@fluentui/react";
import GeneralData from "./Edit/GeneralData";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useGeneralStatesContext, useIdSelectorContext, useSetStatesContext } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import { GetByIdAsync, UpdateAsync, GetByVarietyIdAsync, GetItemsByVarietyIdAsync } from "../../../Functions/Catalogs/Items/ItemStore";
import { DangerMessage, SuccesMessage } from "../../../Models/Shared/BarMessages/Messages";
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Items/Edit';
import BarButtons from "../../Shared/BarButtons/BarButtons";
import { OmniCallout } from "../../Shared/Callout/OmniCallout";
import Detail from "./Detail";
import Comission from "./Edit/Commission";
import Validations from "./Edit/Validations";
import Stock from "./Edit/Stock";
import Resctocking from "./Edit/Restocking";
import ItemsByBranch from "./Edit/ItemsByBranch";
import { GetBranchsAsync } from '../../../Functions/Branchs/BranchStore';
import { GetActives as GetVarieties } from "../../../Functions/Catalogs/Varieties/VarietyStore";
import '../../../Assets/Styles/Catalogs/Items/Item.css'
import "../../../Assets/Styles/Catalogs/Items/Edit.css"
import WeightVolume from "./Edit/WeightVolume";


export default function Edit(){
    const { register, handleSubmit, getValues } = useForm();
    const { setListMessageCatalog, updateItem, setLoadingSkeleton } = useGeneralStatesContext();
    const [openCallout, setOpenCallout] = useState();
    const [item, setItem] = useState([]);
    const id = useIdSelectorContext();
    const changeComponent = useSetStatesContext();
    const[branches,setBranches]=useState();
    const[varieties, setVarieties]=useState();
    

    const getById = async () => {
        setLoadingSkeleton(true);
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            setItem({
                ...result.data
            });
            setLoadingSkeleton(false);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }

    const getBranches = async () => {
        let result = await GetBranchsAsync();
        if(result.isSuccessful){
            var branchesList= result.data.map((branch) => ({
                key:branch.brachKey,
                id:branch.key,
                description: branch.value+" - " + branch.text,
            }));
            setBranches(branchesList);
        }
    }

    const update = async () => {
        item.updateItemStockParams=item.itemStockResults;
        item.updateItemRestockParams=item.itemRestockResults;
        item.literalRefillMeasure=item.literalRefillMeasure==='O'?'P':item.literalRefillMeasure;
        item.amountToRefilled=item.literalRefillMeasure==='C'?1:item.amountToRefilled;
        let result = await UpdateAsync(id, item);
        if (result.isSuccessful) {
            changeComponent(<Detail />, id);
            updateItem({ ...item, acronym: localStorage.getItem("userName"), id:item.sku});
            setListMessageCatalog(message => [...message, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }

    const submit = async () => {
        setOpenCallout(true);
    };

    const getVarieties= async ()=>{
        let result=await GetVarieties();
        if(result.isSuccessful){
            let varietyToCombo=[];
            result.data.map((varietyItem)=>{
                let varietyItemToFilter={
                    value:varietyItem.id,
                    text:varietyItem.name
                }
                varietyToCombo.push(varietyItemToFilter)
            });
            setVarieties(varietyToCombo);
        }
    }

    useEffect(()=>{
        getById();
        getBranches();
        getVarieties();
    },[])

    return(
        <>
            <form onSubmit={handleSubmit(submit)}>
                <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
                <Pivot className="pivot-center-client" overflowBehavior="menu" >
                    <PivotItem headerText="Datos Generales">
                        <GeneralData item={item} setItem={setItem} 
                            varietiesList={varieties}
                            getItemsByVarietyIdAsync={GetItemsByVarietyIdAsync}
                            />
                    </PivotItem>
                    <PivotItem headerText="Peso y volumen">
                        <WeightVolume item={item} setItem={setItem} />
                    </PivotItem>
                    <PivotItem headerText="Existencias">
                        <Stock item={item} setItem={setItem}/>
                    </PivotItem>
                    <PivotItem headerText="Resurtido">
                        <Resctocking item={item} setItem={setItem} />
                    </PivotItem>
                    <PivotItem headerText="Artículos por sucursal">
                        <ItemsByBranch item={item} setItem={setItem} branches={branches} />
                    </PivotItem>
                    <PivotItem headerText="Validaciones">
                        <Validations item={item} setItem={setItem} />
                    </PivotItem>
                    <PivotItem headerText="Comisiones">
                        <Comission item={item} setItem={setItem} />
                    </PivotItem>
                </Pivot>
            </form>
            {
            <OmniCallout
                    open={openCallout}
                    updateIsOpenAlert={setOpenCallout}
                    leftFunction={update}
                    title='Confirmación'
                    content='¿Desea guardar los cambios?'
                    leftButton={'Si'}
                    rightButton={'Regresar'}
                    backgroundColorLeft={'var(--success)'}
                    colorLeft={'white'}
                    backgroundColorRight={'var(--secondary)'}
                    colorRight={'white'}
                    buttonId={'BtnGuardar'}
                    barGroupKey={'rightCommandBar'}
                />
            }
        </>
    )
}