import { Pivot, PivotItem, Image } from '@fluentui/react';
import { useEffect, useState } from 'react';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Items/Detail';
import { useGeneralStatesContext, useIdSelectorContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { GetByIdAsync, UpdateStatusAsync, SetAxiosPrivate } from '../../../Functions/Catalogs/Items/ItemStore';
import { useFieldChangeWatcherContext } from '../../../Context/Controls/FieldChangeWatcherProvider';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { Detail as DetailChanges } from '../../Shared/UserChanges/Detail';
import '../../../Assets/Styles/Catalogs/Items/Detail.css'
import { GeneralData } from './Detail/GeneralData';
import { Stock } from './Detail/Stock';
import { Restocking } from './Detail/Restocking';
import { ItemsByBranch } from './Detail/ItemsByBranch';
import { Validations } from './Detail/Validations';
import { Commission } from './Detail/Commission';
import { GetBranchsAsync } from '../../../Functions/Branchs/BranchStore';
import WeightVolume from './Detail/WeightVolume';


export default function Detail() {
    const id = useIdSelectorContext();
    const fieldWatcher = useFieldChangeWatcherContext();
    const { setListMessageCatalog, updateItemStatus, setLoadingSkeleton, useForeignKey } = useGeneralStatesContext();
    const [item, setItem] = useState([]);
    const [buttonsItem, setButtonsItem] = useState({ leftButtons: leftButtons, rightButtons: rightButtons });
    const [changestatus, setChangestatus] = useState();
    const[branches,setBranches]=useState();
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);

    const getBranches = async () => {
        let result = await GetBranchsAsync();
        if(result.isSuccessful){
            var branchesList= result.data.map((branch) => ({
                key:branch.brachKey,
                id:branch.key,
                description: branch.value+" - " + branch.text,
            }));
            setBranches(branchesList);
        }
    }
    

    const getById = async () => {
        setLoadingSkeleton(true);
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            setItem({
                ...result.data
            });
            setLoadingSkeleton(false);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const updateStatus = async () => {
        let result = await UpdateStatusAsync(id, !item.active);
        if (result.isSuccessful) {
            setItem((prevItem) => {
                return {
                    ...prevItem,
                    active: !item.active,
                };
            });
            setChangestatus(true);
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    function ButtonActive() {
        const updatedButtons = leftButtons.map(button => {
            if (button.key === 'BtnStatus') {
                let statustextbutton = item.active ? "Inactivar" : "Activar";
                let statusiconbutton = item.active ? "inactiveCuvitek" : "activeCuvitek";
                let updatedAuthorizationKey = item.active ? 494 : 495
                return {
                    ...button, text: statustextbutton, iconName: statusiconbutton, authorizationKey: updatedAuthorizationKey,
                    alertprompt: `¿Desea ${statustextbutton.toLowerCase()} el articulo?`,
                    handler: () => { updateStatus() }
                };
            }
            /*
            if (button.key === 'BtnEdit') {
                return {
                    ...button, disabled: !item.active,
                };
            }
            */
            return button;
        });
        return updatedButtons;
    }


    useEffect(() => {
        getById();
        fieldWatcher.hasPendingChangesRef.current = false;
        setChangestatus(undefined);
    }, [id]);


    useEffect(() => {
        if (item) {
            setButtonsItem(prevItem => ({
                ...prevItem,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
        if (changestatus) {
            let data = { ...item };
            data.name = item.warwhouseName;
            updateItemStatus({ ...data, acronym: localStorage.getItem("userName") });
        }
    }, [item?.active]);

    useEffect(() => {
        if (item) {
            setButtonsItem(prevItem => ({
                ...prevItem,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
    }, [changestatus,id]);   

    useEffect(()=>{
        getBranches();
    },[])

    return (
        <>
            {item.length !== 0 &&
                (<BarButtons rightButtons={buttonsItem.rightButtons}
                leftButtons={buttonsItem.leftButtons} />)}
            <Pivot className="pivot-center-client" overflowBehavior="menu" >
                <PivotItem headerText="Información" >
                    <GeneralData item={item} />
                </PivotItem>
                <PivotItem headerText="Peso y volumen">
                    <WeightVolume item={item}/>
                </PivotItem>
                <PivotItem headerText="Existencias" >
                    <Stock item={item} />
                </PivotItem>
                <PivotItem headerText="Resurtido" >
                    <Restocking item={item} />
                </PivotItem>
                <PivotItem headerText="Artículos por sucursal" >
                    <ItemsByBranch item={item} branches={branches} />
                </PivotItem>
                <PivotItem headerText="Validaciones" >
                    <Validations item={item} />
                </PivotItem>
                <PivotItem headerText="Comisiones" >
                    <Commission item={item} />
                </PivotItem>
            </Pivot>
        </>
    )
}