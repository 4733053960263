import { Checkbox, Table, TableBody, TableCell, TableCellLayout, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-components";
import { OmniCard } from "../../../Shared/Card/OmniCard";
import { OmniCheckBox } from "../../../Shared/Controls/Checkbox/OmniCheckBox";
import { useEffect } from "react";

export default function ItemsByBranch(props){
    const onChangeCheckBoxStatus = (event, data) => {
        
        let actual=[];
        props.item.itemByBranches.map((branch)=>{
            if(branch.cuvikBranchId.toString()==event.target.name.toString()){
                branch.show=data.checked;
            }
            actual.push(branch);
        })
            

        props.setItem((prev) => ({
            ...prev,
            itemByBranches: actual,
        }));
    };

    useEffect(()=>{
        let itemByBranchArray=[];
        props.branches.map((branch)=>{
            let itemByBranch=props.item.itemByBranches.find((item)=>item.cuvikBranchId.toString()===branch.id.toString());
            if(itemByBranch===undefined){
                itemByBranch={
                    id:'00000000-0000-0000-0000-000000000000',
                    cuvikBranchId:branch.id,
                    cuvikBranchKey:branch.key,
                    show:false
                };
            }
            itemByBranchArray.push(itemByBranch);
        });

        props.setItem((prev) => ({
            ...prev,
            itemByBranches: itemByBranchArray.sort((a,b) => (a.cuvikBranchKey > b.cuvikBranchKey) ? 1 : ((b.cuvikBranchKey > a.cuvikBranchKey) ? -1 : 0)),
        }));
    },[])

    return(
        <div className='container-fluid container-scroll-two-bars'>
        <div className='row'>
            <div className='col-lg-12 col-12'>
                <OmniCard title='Artículos por sucursal'  classCard="long-card">
                    <div className='row'>
                        <div className='col-lg-12 col-md-12'>
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableHeaderCell className="header-cell-itemByBranch">
                                            ID
                                        </TableHeaderCell>
                                        <TableHeaderCell className="header-cell-itemByBranch">
                                            Nombre de la sucursal
                                        </TableHeaderCell>
                                        <TableHeaderCell className="header-cell-itemByBranch">
                                            Se muestra
                                        </TableHeaderCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                {
                                    props.item.itemByBranches?.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell >
                                                <TableCellLayout className="cell-bold">
                                                    {item.cuvikBranchKey}
                                                </TableCellLayout>
                                            </TableCell>
                                            <TableCell >
                                                <TableCellLayout>
                                                    {   
                                                        props.branches?.find(b=>b.id==item.cuvikBranchId)?.description?.split('-')[1]
                                                    }
                                                </TableCellLayout>
                                            </TableCell>
                                            <TableCell >
                                                <TableCellLayout>
                                                    <OmniCheckBox key={item.cuvikBranchId} 
                                                        onChange={onChangeCheckBoxStatus} 
                                                        name={item.cuvikBranchId} 
                                                        propertyChecked={item.show} />     
                                                </TableCellLayout>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </OmniCard>
            </div>
        </div>
    </div> 
    )
}