const url = {
    base: '/warehouse',
    add: '/add',
    get: '/getbyid',
    getToAutocomplete: '/gettoautocomplete',
    updateStatus: '/setstatus',
    update: '/update',
}
var axiosPrivate;

export const SetAxiosPrivate = (axiosPrivateParam) => {
    axiosPrivate = axiosPrivateParam;
}

export const GetByIdAsync = async (id) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(url.base + url.get, { params: { id: id } });
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    }
    catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}

export const GetToAutocomplete = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(url.base + url.getToAutocomplete,
            null);
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        if (!err?.response) {
            result.errorMessage = "No server response";
        } else if (err.response?.status === 400) {
            result.errorMessage = err.response?.data?.errorMessage
        }
    }
    return result;
}

export const AddAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        //let cuvikBranch = validateCuvikBranch(data.cuvikBranchId);
        let warehouseResult = {
            ...data,
            UpdateUserId: localStorage.getItem("userId"),
            updateUserName: localStorage.getItem("userName"),
            cuvikBranchId: data.cuvikBranchId,
            cuvikBranchKey: data.cuvikBranchKey,
            dailyReceptions: validateNumberEmpty(data.dailyReceptions),
            maximumTons: validateNumberEmpty(data.maximumTons),
            maximumVolume: validateNumberEmpty(data.maximumVolume),
            maximumBoxes: validateNumberEmpty(data.maximumBoxes),
            inventoryDays: validateNumberEmpty(data.inventoryDays),
            listDaysReceiveWarehouseResult: filterTrueIndices(data.listDaysReceiveWarehouseResult)
        }
        const response = await axiosPrivate.post(url.base + url.add,
            JSON.stringify({
                ...warehouseResult
            })
        );
        if (response.data.isSuccessful) {
            result.data = response.data.id;
            result.successMessage = `El almacén se guardó exitosamente`;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};

export const UpdateAsync = async (id, data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        //let cuvikBranch = validateCuvikBranch(data.cuvikBranchId);
        let warehouseResult = {
            ...data,
            UpdateUserId: localStorage.getItem("userId"),
            updateUserName: localStorage.getItem("userName"),
            cuvikBranchId: data.cuvikBranchId,
            cuvikBranchKey: data.cuvikBranchKey,
            dailyReceptions: validateNumberEmpty(data.dailyReceptions),
            maximumTons: validateNumberEmpty(data.maximumTons),
            maximumVolume: validateNumberEmpty(data.maximumVolume),
            maximumBoxes: validateNumberEmpty(data.maximumBoxes),
            inventoryDays: validateNumberEmpty(data.inventoryDays),
            listDaysReceiveWarehouseResult: filterTrueIndices(data.listDaysReceiveWarehouseResult)
        }

        const response = await axiosPrivate.put(url.base + url.update,
            JSON.stringify({
                id: id,
                ...warehouseResult
            }));
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `El almacén se actualizó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    }
    catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}

export const UpdateStatusAsync = async (id, status) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(url.base + url.updateStatus,
            JSON.stringify({
                id: id,
                active: status,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        let statustext = status ? "activado" : "desactivado";
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.data = !status;
            result.successMessage = `El almacén ha sido ${statustext} exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }


    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}




//const validateCuvikBranch = (cuvikBranchId) => {
//    if (cuvikBranchId != '' && cuvikBranchId !== undefined) {
//        let selectBranch = cuvikBranchId.split('#');
//        return {
//            cuvikBranchId: selectBranch[0],
//            cuvikBranchKey: selectBranch[1]
//        }
//    }
//    else {
//        return {
//            cuvikBranchId: '',
//            cuvikBranchKey: ''
//        }
//    }
//}

const filterTrueIndices = (list) => list
    .map((value, index) => value ? index : null)
    .filter(index => index !== null);

const validateNumberEmpty = (value) => value === '' ? 0 : value;


const getErrorText = (err) => {
    if (!err?.response) {
        return "No server response";
    }
    else if (err.response?.status === 400) {
        return err.response?.data?.errorMessage
    }
    else if (err.response?.status === 500) {
        return err.response?.data?.detail;
    }
}
