import Add from "../../../../../Components/Catalogs/Items/Add";
import Edit from "../../../../../Components/Catalogs/Items/Edit";
import ItemAuthorizations from "../../../Authorizations/Item";
export const leftButtons= [{
    key: 'BtnEdit',
    text: 'Editar',
    iconName: 'editcuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--primary)',
    component: <Edit />,
    authorizationKey: ItemAuthorizations.Save,
}
/*, {
    key: 'BtnStatus',
    text: 'Inactivar',
    iconName: 'inactivecuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--primary)',
    backgroundColorButton: '',
    backgroundColorButtonHover: '',
    authorizationKey: ItemAuthorizations.Delete,
}*/
];

export const rightButtons = [
    {}
   /* {
    key: 'BtnNew',
    text: 'Nuevo',
    iconName: 'addcuvitek',
    fontColor: '#FFFFFF',
    iconColor: '#fff',
    iconHover: '#fff',
    backgroundColorButton: 'var(--primary)',
    backgroundColorButtonHover: 'var(--primary)',
    isButton: true,
    component: <Add />,
    authorizationKey: ItemAuthorizations.Reactive,
}*/
]
